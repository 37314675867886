.gpt3_navbar
{   
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 6rem;
}

.gpt3_navbar-links
{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3_logo
{
    cursor: pointer;
    margin-right: 2rem;
}

.gpt3_logo img
{
    width: 62px;
    height: 17px;
}

.gpt3_navbar_container
{
    display: flex;
    flex-direction: row;
}

.gpt_navbar-mainContainer
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.gpt3_navbar-sign
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3_navbar_container p,
.gpt3_navbar-sign p,
.gpt3_navbar-menu_container p
{
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.gpt3_navbar-sign button,
.gpt3_navbar-menu-sign button
{
    padding: 0.5rem 1rem;
    background-color: #ff4820;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-family);
    cursor: pointer;
    border-radius: 5px;
    border: none;
    outline: none;
}

.gpt3_navbar-menu
{
    display: none;
    margin-left: 2rem;
    position: relative;
}

.gpt3_navbar-menu svg
{
    cursor: pointer;
}

.gpt3_navbar-menuContainer
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    background: var(--color-footer);
    padding: 1rem;
    position: absolute;
    min-height: 420px;
    top: 70px;
    right: 0;
    min-width: 200px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.gpt3_navbar-menuContainer p 
{
    margin: 1rem 0;
}

.gpt3_navbar-menu-sign
{
    display: none;
}

@media screen and (max-width: 1024px)
{
    .gpt3_navbar_container
    {
        display: none;
    }

    .gpt3_navbar-menu
    {
        display: flex;
    }
    

    .gpt3_navbar-menu-links
    {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;
        color: #fff;
    }
        
    .gpt3_navbar-menu-links p a 
    {
        cursor: pointer;
    }

    .gpt3_navbar-menu-sign
    {
        display: block;
    }


}

@media screen and (max-width: 768px)
{
    .gpt3_navbar
    {
        padding: 1.5rem 4rem;
    }
    .gpt3_navbar-menuContainer
    {   
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        height: 300px;
        top: 80px;
    }
    
    .gpt3_navbar-menu-links
    {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
        color: #fff;
        cursor: pointer;
    }
}

@media screen and (max-width: 425px)
{
    .gpt3_navbar
    {   
        padding: 2rem;
    }
    .gpt3_navbar-sign
    {
        display: none;
    }
    .gpt3_navbar-menuContainer
    {
        min-height: 420px;
        min-width: 250px;
    }

}