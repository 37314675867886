.blog_container
{
    display: flex;
    flex-direction: column;
}

.blog-heading
{
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}

.blog-heading h1
{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;
    letter-spacing: -0.04rem;
    color: #fff;
}

.blog-items
{
    display: flex;
    flex-direction: row;
}

.blog-item1
{
    flex: 0.55;
    margin-right: 2rem;
    height: 100%;
}

.blog-item2
{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
    column-gap: 20px;
    row-gap: 10px;
}

@media screen and (max-width: 768px)
{
    .blog-items
    {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .blog-item1
    {
        flex: 0.65;
        margin-right: 0rem;
    }

    .blog-item2
    {
        flex: 1;
        gap: 10px;
    }
}

@media screen and (max-width: 425px)
{
        .blog-items
        {
            display: flex;
            flex-direction: column;
        }

        .blog-item1
        {
            flex: 1;
            height: 100%;
        }

        .blog-item2
        {
            flex: 1;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            height: 100%;
            column-gap: 20px;
        }
        .blog-heading h1
        {
            font-size: 22px;
            line-height: 35px;
        }

}