.feature_container
{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px;
}

.feature-title
{
    flex:1;
    max-width: 180px;
    margin-right: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.feature-title h1
{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.04rem;
    color: #fff;
    margin-top: -6px;
}

.feature-title div
{   
    position: relative;
    top:-5px;
    left:0;
    width: 45px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.40rem;
}

.feature-text
{
    flex:2;
    max-width: 600px;
    display: flex;
}

.feature-text p
{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
}

@media screen and (max-width: 768px)
{
    .feature-title h1
    {
        font-size: 14px;
    }
    .feature-text p
    {
        font-size: 12px;
    }
}

@media screen and (max-width: 425px)
{
    .feature_container
    {
        display: flex;
        flex-direction: column;
    }
}