.possibility_Container
{
    display: flex;
    flex-direction: row;
    gap:30px;
}

.possibility-image
{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;
}

.possibility-image img
{
    width: 100%;
    height: 100%;
}

.possibility-content
{
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
}

.possibility-content h4
{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #022027;
}

.possibility-content h4:last-child
{
    color: #f8f8f89d;
}

.possibility-content h1
{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #fff;
    text-transform: uppercase;
}

.possibility-content p
{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: rgb(253, 255, 109);
    margin-bottom: 2rem;
}

@media screen and (max-width: 768px)
{
    .possibility_Container
    {
        flex-direction: column;
    }

    .possibility-content
    {
        margin-top: 2rem;
    }
    .possibility-image
    {
        margin: 1rem 0;
    }
}

@media screen and (max-width: 425px)
{
    .possibility_Container
    {
        flex-direction: column;
    }

    .possibility-content
    {
        margin-top: 2rem;
    }
    .possibility-image
    {
        margin: 1rem 0;
    }
    .possibility-content h4
    {
        font-size: 16px;
    }

    .possibility-content h1
    {
        font-size: 25px;
    }

    .possibility-content p
    {
        font-size: 14px;
    }

}