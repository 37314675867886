.article_container
{
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #13579b 25%, rgb(21, 89, 156) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #146cc4 25%, rgb(33, 111, 189) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #1d64aa 25%, rgb(20, 92, 165) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #195694 25%, rgb(21, 84, 146) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #115396 25%, rgb(6, 82, 158) 85%, rgba(27, 120, 222, 1) 100%);
    height: 100%;
    width: 100%;
}

.article-image
{       
    background: var(--color-footer);
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #13579b 25%, rgb(21, 89, 156) 85%, rgba(27, 120, 222, 1) 100%);
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #146cc4 25%, rgb(33, 111, 189) 85%, rgba(27, 120, 222, 1) 100%);
    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #1d64aa 25%, rgb(20, 92, 165) 85%, rgba(27, 120, 222, 1) 100%);
    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #195694 25%, rgb(21, 84, 146) 85%, rgba(27, 120, 222, 1) 100%);
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #115396 25%, rgb(6, 82, 158) 85%, rgba(27, 120, 222, 1) 100%);
    height: 100%;
    width: 100%;
    padding: 30px;
    border-bottom: 2px solid #fff;
}

.article-image img
{
    height: 100%;
    width: 100%;
}

.article-items
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    height: 100%;
    gap: 10px;
}

.article-items div
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.article-items p
{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #fff;
}

.article-items h3
{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 35px;
    color: #fff;
    cursor: pointer;
    margin-right: 2rem;
}

.article-items p:last-child
{
    cursor: pointer;
}

@media screen and (max-width: 768px)
{
    .article-items h3
    {
        font-size: 16px;
        line-height: 25px;
    }
}

@media screen and (max-width: 425px)
{
    .article-items p
    {
        font-size: 15px;
        line-height: 30px;
    }

    .article-items h3
    {
        font-size: 16px;
        line-height: 35px;
    }

}

@media screen and (max-width: 320px)
{
    .article-items p
    {
        font-size: 12px;
        line-height: 30px;
    }

    .article-items h3
    {
        font-size: 14px;
        line-height: 35px;
    }

}