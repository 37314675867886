.gpt3_container
{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #13579b 25%, rgb(21, 89, 156) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #146cc4 25%, rgb(33, 111, 189) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #1d64aa 25%, rgb(20, 92, 165) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #195694 25%, rgb(21, 84, 146) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #115396 25%, rgb(6, 82, 158) 85%, rgba(27, 120, 222, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gpt3-top
{
    display: flex;
}

.gpt3-top, .gpt3-bottom
{
    margin: 0;
}

.gpt3-heading
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 1rem;
}

.gpt3-heading h1
{
    font-size: 28px;
    line-height: 30px;
    font-weight: 600;
    max-width: 500px;
    font-family: var(--font-family);
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gpt3-heading p
{
    font-size: 18px;
    font-family: var(--font-family);
    font-weight: 500;
    line-height: 28px;
    color: var(--color-subtext);
    cursor: pointer;
}

.gpt3-bottom
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2rem;
}

@media screen and (max-width: 768px) 
{   
    .gpt3_container
    {
        padding: 1rem;
    }
    .gpt3-heading
    {
        margin: 0rem;
    }

    .gpt3-heading h1
    {
        font-size: 18px;
        line-height: 30px;
    }
    .gpt3-heading p
    {
        font-size: 10px;
        line-height: 20px;
    }


}

@media screen and (max-width: 425px) 
{
    .gpt3-heading
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .gpt3-heading h1
    {
        flex-wrap: wrap;
        text-align: center;
        font-size: 18px;
    }
}   

@media screen and (max-width: 375px) 
{
    .gpt3-heading
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .gpt3-heading h1
    {
        flex-wrap: wrap;
        text-align: center;
        font-size: 18px;
    }
}   

@media screen and (max-width: 320px) 
{
    .gpt3-heading
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .gpt3-heading h1
    {
        flex-wrap: wrap;
        text-align: center;
        font-size: 16px;
    }
    .gpt3_container
    {
        gap:20px;
    }

}   