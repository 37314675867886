.gpt3_header
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

.gpt3_header-content
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex:2.6;
}

.gpt3_header-content h1
{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 44px;
    line-height: 70px;
    letter-spacing: -0.04rem;
    word-spacing: 2px;
    color: #fff;
}

.gpt3_header-content p
{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: rgb(32, 32, 32);
    margin-top: 1rem;
}

.gpt3_header-input
{
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.gpt3_header-input input 
{
    background: rgb(235, 235, 235);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 20px;
    color: #222;
    outline: none;
    border: none;
    padding: 10px;
    flex: 2;
    width: 100%;
    min-height: 46px;
}

.gpt3_header-input button
{   
    flex: 0.6;
    width: 100%;
    min-height: 46px;
    background: #ff4820;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    line-height: 29px;
    color: #fff;
    padding: 0 5px;
    cursor: pointer;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gpt3_header-people
{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
    flex: 1;
}

.gpt3_header-people img
{
    width: 181.79px;
    height: 38px;
}

.gpt3_header-people p
{
    margin: 0 0 0 1rem;
    font-weight: 500;
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3_header-image
{
    flex:2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gpt3_header-image img
{
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 1024px)
{   

    .gpt3_header
    {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    
    .gpt3_header-content
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 2.6;
    }
    
    .gpt3_header-image
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 2.1;
        margin-bottom: 2rem;
    }
    
    .gpt3_header-content h1
    {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 36px;
        line-height: 50px;
        letter-spacing: -0.01rem;
    }

    .gpt3_header-content p
    {
        font-family: var(--font-family);
        font-size: 18px;
        line-height: 29px;
        margin-top: 1rem;
    }

    .gpt3_header-input
    {
        width: 100%;
        margin: 2rem 0 1rem;
        display: flex;
    }

    .gpt3_header-input input 
    {
        font-size: 18px;
        line-height: 25px;
        flex: 2.1;
        width: 100%;
        min-height: 50px;
    }

    .gpt3_header-input button
    {   
        flex: 0.8;
        width: 100%;
        min-height: 50px;
        background: #ff4820;
        font-family: var(--font-family);
        font-size: 18px;
        font-weight: bold;
        line-height: 27px;
        color: #fff;
        padding: 0 5px;
        cursor: pointer;
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .gpt3_header-image img
    {
        width: 100%;
        height: 100%;
    }
    .gpt3_header-people 
    {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .gpt3_header-people p
    {
        margin: 0 0 1rem;
        font-weight: 500;
        font-family: var(--font-family);
        font-size: 16px;
        line-height: 38px;
        color: #fff;
        text-align: center;
    }
}

@media screen and (max-width: 768px)
{
    .gpt3_header
    {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    
    .gpt3_header-content
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 2.4;
    }
    
    .gpt3_header-image
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 2;
    }
    
    .gpt3_header-content h1
    {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 22px;
        line-height: 35px;
        letter-spacing: -0.01rem;
    }

    .gpt3_header-content p
    {
        font-family: var(--font-family);
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3_header-input
    {
        width: 100%;
        margin: 1rem 0 1rem;
        display: flex;
    }

    .gpt3_header-input input 
    {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-family: var(--font-family);
        font-size: 16px;
        padding: 10px;
        flex: 2.3;
        width: 100%;
        height: 20px;
    }

    .gpt3_header-input button
    {   
        flex: 1;
        width: 100%;
        height: 20px;
        background: #ff4820;
        font-family: var(--font-family);
        font-size: 14px;
        font-weight: bold;
        line-height: 27px;
        color: #fff;
        padding: 0 5px;
        cursor: pointer;
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .gpt3_header-image img
    {
        width: 100%;
        height: 100%;
    }
    .gpt3_header-people 
    {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 0 1rem;
        gap: 10px;
    }
    .gpt3_header-people p
    {
        text-align: left;
        margin: 0 0 1rem;
    }

}


@media screen and (max-width: 425px)
{
    .gpt3_header
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3rem;
    }
    .gpt3_header-content h1
    {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 25px;
        line-height: 40px;
        letter-spacing: -0.04rem;
    }

    .gpt3_header-content p
    {
        font-family: var(--font-family);
        font-size: 14px;
        line-height: 28px;
        margin-top: 1rem;
    }

    .gpt3_header-input
    {
        width: 100%;
        margin: 2rem 0 1rem;
        display: flex;
    }

    .gpt3_header-input input 
    {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-family: var(--font-family);
        font-size: 14px;
        line-height: 27px;
        padding: 10px;
        flex: 2;
        width: 100%;
        min-height: 40px;
    }

    .gpt3_header-input button
    {   
        flex: 0.6;
        width: 100%;
        min-height: 40px;
        background: #ff4820;
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: bold;
        line-height: 27px;
        color: #fff;
        padding: 0 5px;
        cursor: pointer;
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .gpt3_header-people
    {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 4rem;
    }
}


@media screen and (max-width: 375px)
{
    .gpt3_header-content h1
    {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 22px;
        line-height: 45px;
        letter-spacing: -0.01rem;
    }

    .gpt3_header-content p
    {
        font-family: var(--font-family);
        font-size: 14px;
        line-height: 28px;
        margin-top: 1.4rem;
    }

    .gpt3_header-input
    {
        width: 100%;
        margin: 2rem 0 1rem;
        display: flex;
    }

    .gpt3_header-input input 
    {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-family: var(--font-family);
        font-size: 14px;
        line-height: 27px;
        padding: 10px;
        flex: 2;
        width: 100%;
        min-height: 35px;
    }

    .gpt3_header-input button
    {   
        flex: 0.9;
        width: 100%;
        min-height: 35px;
        background: #ff4820;
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: bold;
        line-height: 27px;
        color: #fff;
        padding: 0 5px;
        cursor: pointer;
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .gpt3_header-people
    {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 2rem;
    }
}


@media screen and (max-width: 320px)
{
    .gpt3_header-content h1
    {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 22px;
        line-height: 25px;
        letter-spacing: -0.01rem;
    }

    .gpt3_header-content p
    {
        font-family: var(--font-family);
        font-size: 14px;
        line-height: 28px;
        margin-top: 1.4rem;
    }

    .gpt3_header-input
    {
        width: 100%;
        margin: 2rem 0 1rem;
        display: flex;
    }

    .gpt3_header-input input 
    {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-family: var(--font-family);
        font-size: 14px;
        line-height: 27px;
        padding: 10px;
        flex: 2;
        width: 100%;
        min-height: 35px;
    }

    .gpt3_header-input button
    {   
        flex: 0.9;
        width: 100%;
        min-height: 35px;
        background: #ff4820;
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: bold;
        line-height: 27px;
        color: #fff;
        padding: 0 5px;
        cursor: pointer;
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .gpt3_header-people
    {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 2rem;
    }
}