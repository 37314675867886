.footer_container
{
    border: 1px solid #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: var(--color-footer);
}

.heading
{
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
}

.heading h1
{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    letter-spacing: -0.04rem;
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.btn
{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;
    margin-bottom: 5rem;
    cursor: pointer;
}

.btn p
{
    box-sizing: border-box;
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    word-spacing: 3px;
}

.links
{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
}

.links div
{
    width: 250px;
    margin: 1rem;
}

.logo
{
    display: flex;
    flex-direction: column;
}

.logo img
{
    width: 118px;
    height: 30px;
    margin-bottom: 1rem;
}

.logo p
{
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 27px;
    color: #fff;
}

.links-container
{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.links-container h4
{
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 22px;
    color: #fff;
    margin-bottom: 1rem;
}

.links-container p
{
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    margin: 0.5rem 0;
    cursor: pointer;    
    width: max-content;
}

.copyright
{
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.copyright p
{
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
}

@media screen and (max-width: 768px)
{
    .heading h1
    {
        font-size: 30px;
        line-height: 40px;
    }
}

@media screen and (max-width: 425px)
{
    .heading h1
    {
        font-size: 22px;
        line-height: 34px;
    }
    .btn p
    {
        font-size: 12px;
        line-height: 20px;
    }
    .links-container h4
    {
        font-family: var(--font-family);
        font-size: 16px;
        line-height: 22px;
        color: #fff;
        margin-bottom: 1rem;
    }

    .links-container p
    {
        font-family: var(--font-family);
        font-size: 12px;
        line-height: 24px;
        color: #fff;
        margin: 0.5rem 0;
        cursor: pointer;    
        width: max-content;
    }

    .logo p
    {
        font-size: 12px;
        line-height: 23px;
    }

}